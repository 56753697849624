.report-bar {
  background-color: $ln-color-grey-v-light;
  border-radius: 3px;
  height: $ln-space;
  width: 100%;
}

.report-bar--completion {
  background-color: $ln-color-orange;
  border-radius: 3px;
  height: $ln-space;
  width: calc(var(--completion) * 100%);
}