@keyframes fadeInUp {
    from {
        transform: translate3d(0,0,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,0,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.filter-amount {
    height: 20px;
    width: 40px;
    background-color: $ln-color-grey-dark;
    border-radius: 50%;
    position: absolute;
    color: $ln-color-white;
    font-size: 14px;
    top:0;
    right:0;
    border: 1px solid $ln-color-white;
    border-radius: 16px;
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
}

.attribute-box {
    position: relative;
    width: 125px;
    height: 150px;
    display: inline-block;
    padding-top:15px;
    margin-bottom: 5px;
    margin-right:8px;
    transition: transform .2s;
    border: 2px solid $ln-color-grey-light;
    border-radius:4px;
    background: $ln-color-white;
    text-align: -webkit-center;

    &.compact {
        width: auto;
        height: 40px;
        padding: 15px;
        margin-right: 10px;
        .name-container {
            margin-left: 5px;
            position: relative;
            left: 4px;
            top: -7px;
        }
        .icon-container {
            position: absolute;
            left: -10px;
            top: -3px;
            background: transparent;
            &.skeleton {
                left: 3px;
                top: 8px;
                width: 20px;
                height: 20px;
                background: $ln-color-grey-v-light;
            }
        }
    }

    &.active {
        border: 2px solid orange;
        background: #FCEDE5;
    }

    .active {
        border: 2px solid orange;
        background: #FCEDE5;
    }

    .name-container {
        position: absolute;
        bottom: 30px;
        padding: 0;
        font-weight: bold;
        width: 100%
    }

    .activity-count-container {
        position: absolute;
        bottom: 10px;
        left: 16px;
        padding: 0;
        margin: 0;
    }
}

.attribute-tag {
    max-width: 100%;
    display: inline-block;

    padding-left: $ln-space / 2;
    padding-right: $ln-space;

    border: 1px solid #d7d7d7;
    border-radius: 30px;
    margin-right: $ln-space; 
    margin-bottom: $ln-space; 

    position: relative;
    background: $ln-color-white;
    transform: translateY(-25%);
    transition: all 0.7s ease;

    p {
        display: inline-block;
        margin-bottom: 0px;
    }

    &:hover {
        background-color: $ln-color-grey-v-light;
        cursor: pointer;
    }

    .dismiss-button {
        padding: 0rem 0rem 0rem 1rem;
        position: relative;
        display: inline-block;
        min-width: 10px;
        min-height: 10px;
        margin: 0px 0px 0px 5px;
        right: 5px;

        &:hover {
             background-color: transparent !important;
        }
    }
}

.icon-container {
    width:50px;
    height: 50px;
    border-radius: 50%;
    background: $ln-color-grey-v-light;
    display: flex;
    justify-content: center;
    align-items: center;

    &:empty {
        display: none;
    }
}

#attributes-header-container-planning {
    margin-left:1.5%;
    margin-top:-1rem;
    height: 200px;
}

#attributes-header-container-today {
    margin-top:-1rem;
}

.filtering-overflow {
    overflow: scroll;
}