$item-margin: 1rem;
$desktop-margin-top: 12vh;

@mixin planning-view($item-width) {
    padding-bottom: 7rem;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    display: none;

    .u-border-grey {
        border: 1px solid $ln-color-grey-light;
    }

    @include ln-respond-to("md") {
        display: flex;
        justify-content: center;
    }

    .c-day-card {
        display: inline-block;
        position: relative;
        vertical-align: top;
        min-height: 30vh;
        min-width: $item-width;
        max-width: $item-width;

        .header {
            color: $ln-color-grey;
            font-weight: 400pt;
            text-align: left;
        }

        .header-bold {
            @extend .header;

            font-weight: bold;
            display: inline-block;
            color: $ln-color-black;
        }

        .u-invisible {
            visibility: hidden;
        }

        .ln-c-status-card__content {
            padding-left: 0.5rem;
        }
    }

    .c-more-activities-status-card {
        padding: 0.5rem;
    }

    .c-activity-past {
        opacity: 0.5;
    }

    .c-past-day {
        .c-calendar-item {
            opacity: 0.5;
        }
        background-color: rgba(242, 242, 242, 0.5);
    }

    .c-cal-date {
        &.today {
            background-color: $ln-color-orange;
            color: $ln-color-white;
        }
        border-radius: 100%;
        padding: $ln-space / 2 $ln-space;
    }

    .c-activity-item {
        position: relative;
        z-index: 3;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        margin-top: 10px;

        &.standard {
            border: 1px solid $ln-color-grey-light;
        }

        &.urgent {
            border-bottom: 1px solid $ln-color-grey-light;
            border-top: 1px solid $ln-color-grey-light;
            border-right: 1px solid $ln-color-grey-light;
        }

        .ln-c-status-card__content {
            padding-right: 0rem;
        }

        .ln-o-flag__body {
          overflow: hidden;
        }

        min-width: $item-width;
        max-width: $item-width;


        &.x2 {
            min-width: calc((#{$item-width} * 2) + (#{$item-margin} * 1));
        }

        &.x3 {
            min-width: calc((#{$item-width} * 3) + (#{$item-margin} * 2));
        }

        &.x4 {
            min-width: calc((#{$item-width} * 4) + (#{$item-margin} * 3));
        }

        &.x5 {
            min-width: calc((#{$item-width} * 5) + (#{$item-margin} * 4));
        }

        &.x6 {
            min-width: calc((#{$item-width} * 6) + (#{$item-margin} * 5));
        }

        &.x7 {
            min-width: calc((#{$item-width} * 7) + (#{$item-margin} * 6));
        }

        &.x8 {
            min-width: calc((#{$item-width} * 8) + (#{$item-margin} * 7));
        }
    }

    &.period {
        padding-bottom: initial;
        margin-top: initial;
        padding-top: 1rem;
        .c-day-card {
            min-height: initial;
        }
    }
}

.c-planning-view {
    @include planning-view(11.8vw);
}

.c-planning-view-condensed {
    @include planning-view(8.6vw);
}

.c-mobile-past-day {
    .c-calendar-item {
        opacity: 0.5;
    }
}

.c-mobile-day-card {
    border: 1px solid transparent;
    &.today {
        border-color: $ln-color-orange;
    }
}

.c-calendar-title {
    margin-bottom: 0!important;
}

.c-pagination-container {
    background-color: $ln-color-white;

    .u-week-view-title {
        display: inline-block;
        min-height:58px;

        @include ln-respond-to("md") {
            margin-left: 5%;
        }
    }

    .u-week-view-filter {
        @include ln-respond-to("md") {
            margin-top: $ln-space*2;
            margin-left: 5%;
        }
    }

    .u-pagination-buttons {
        @include ln-respond-to("md") {
            margin-right: 6%;
        }
    }

    margin-top: 0 !important;
    padding-top: 0.5rem;
    padding-left: 1rem;
}

.c-pagination-container-mobile {
    background-color: $ln-color-white;
    padding-top: 0.5rem;

    .c-mobile-header-grid-item {
        padding-left: 0!important;
    }
}

.c-grouped-item__count {
    background-color: $ln-color-grey;
    border-radius: 50%;
    color: $ln-color-white;
    font-weight: bold;
    font-size: 14px;
    margin-right: $ln-space;
    padding: $ln-space / 2 $ln-space;
    text-align: center;
}

.c-grouped-modal__header {
    margin-bottom: 1px;
}

.dot {
    height: 3px;
    width: 3px;
    border-radius: 50%;
    display: inline-block;
    top: 0;
}

.c-mobile-planning-view-header {
    overflow-y: hidden;
    height:inherit;
}

a[role=listbox]:focus,
a[role=listbox]:hover,
a[role=listbox]:active {
    color: initial;
    box-shadow: initial;
    -webkit-box-shadow: initial;
    background-color: transparent;
}

a[role=listbox] {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#mobile-header-days-unordered-list {
    display: flex;
    justify-content: space-around;
    padding: 0.5rem;
    margin: 0;
    margin-bottom: -2rem;
}

.c-compact-link-status-card {
    height: 7vh;
    margin-top: 10px;
    padding-top: 4px;
    padding-bottom: 0px;
    -webkit-box-shadow: -1px 9px 17px -5px rgba(158, 158, 158, 1);
    -moz-box-shadow: -1px 9px 17px -5px rgba(158, 158, 158, 1);
    box-shadow: -1px 9px 17px -5px rgba(158, 158, 158, 1);
}

.c-modal-no-backdrop {
    background-color: transparent;

    .ln-c-modal__body {
        -webkit-box-shadow: 0px 0px 18px 1px rgba(158, 158, 158, 1);
        -moz-box-shadow: 0px 0px 18px 1px rgba(158, 158, 158, 1);
        box-shadow: 0px 0px 18px 1px rgba(158, 158, 158, 1);
    }

    .ln-c-modal__heading{
        font-size: 22px;
    }
}

.mobile-panel-drag-control {
    z-index:1000;
    margin-top:-20px;
    height:20px;
    cursor: ns-resize;
    touch-action: none;
    width:100%;
}
.mobile-panel-drag-bar {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: auto;
    cursor: ns-resize;
    touch-action: none;
    background-color: grey;
    height: 5px;
    width: 25px;
    border-radius: 25%
}
.mobile-panel-loading-indicator {
    position: absolute;
    top: 1px !important;
    background-color: rgba(255,255,255,1) !important;
}