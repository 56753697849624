.todo-item-box {
    border: 1px solid $ln-color-grey-light;
}

.no-activities-message {
    padding-top: 5%;
}

.listElementLabel {
    font-size: 0.9em;
    margin-top: 0.5rem;
    color: #4C4C4C;
}

.activity-title {
    word-wrap: break-word;

    &:hover {
        svg {
        fill: none;
        }
    }
}

.navigation-bar-header {
  width: 100%;
  align-items: stretch;

  li {
    width: 100%;
    align-items: stretch;
    a {
        justify-content: center;
    }
  }

}

.attributes-header {
    background-color: white;
    font-size:  0.5rem;
}


@media screen {
    #section-heading {
        display: none;
    }
}

.startedBadge{
  width: 68px;
  height: 28px;
  line-height: 28px;
  background: $ln-color-green;
  color: white;
  text-align: center;
  @include ln-respond-to("xs") {
    width: 84px;
    height: 36px;
    line-height: 36px;
  }
  @include ln-respond-to("sm") {
    width: 4.2rem;
    height: 1.8rem;
    line-height: 1.8rem;
  }
}

.activity-error-icon {
    color: $ln-color-red;
}

.activity-icon-padder {
  display: inline-block;
  margin-left: $ln-space * 2;
  padding-top: $ln-space / 2;
  vertical-align: middle;
}