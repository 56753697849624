.DateTimePicker-colleague {
  display: flex;
  margin: '10px';
  padding: '10px';
  
  &.hidden {
    display: none;
  }

  .hidden {
    display: none;
  }

  div.DateInput {
    input {
      border-radius: 0 !important;
    }
  }

  &.error {
    p.ln-c-field-info--error {
      padding-top: 0;
    }
  }

  .datePicker-item {
    &.hidden {
      display: none;
    }
  }

  @media (min-width: 601px) {
    .datePicker-item {
      margin-right: 2rem;
    }
  }

  @media (max-width: 600px) {
    .datePicker-item {
      display: block;

      &.hidden {
        display: none;
      }
    }
  }

  &.error {
    input,
    select {
      border: 1px solid #db3e34;
      border-left: 6px solid #db3e34;

      &:focus {
        border-left-color: #db3e34;
      }
    }
  }

  .SingleDatePickerInput {
    border: none;
  }

  .ln-c-select {
    line-height: 26px;
  }

  .Time-selector {
    margin: auto;
    width: 150%;
    padding: 10px;
  }
}

.datetime-picker-left {
  @media (min-width: 601px) {
    .SingleDatePicker_picker__directionLeft {
      left: -250% !important;
    }
  }
}

.datetime-picker-top {
  @media (min-width: 601px) {
    .SingleDatePicker_picker__directionLeft {
      bottom: -100% !important;
    }
    .CalendarMonth_caption_1 {
      margin-left: 3rem;
    }
  }
}