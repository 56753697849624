.error-page{
  margin-left: 2rem;
  overflow-x: hidden;
  @include ln-respond-to('sm') {
    margin-left: 2rem;
  }
  @include ln-respond-to('md') {
    margin-left: 13rem;
  }
  @include ln-respond-to('lg') {
    margin-left: 22rem;
  }
  @include ln-respond-to('xl') {
    margin-left: 40rem;
  }
}

.error-message{
  font-size: 4em;
  line-height: 1;
  text-align: left;
  margin-top: -2em;
  @include ln-respond-to('sm') {
    font-size: 6em;
  }
  @include ln-respond-to('md') {
    font-size: 8em;
  }
}

.error-instructions{
  margin-right: 2rem;
}

.error-image {
  margin-left: 2rem;
  @include ln-respond-to('sm') {
    margin-left: 0rem;
  }
  @include ln-respond-to('md') {
    max-width: 50rem;
  }
}

.redirect-button{
  width: 100%;
  @include ln-respond-to('sm'){
    width:inherit;
  }
}

.no-connection-container {
  text-align:center;
  margin-top:80px;
}

.no-connection-refresh-button {
  width: 300px;
}