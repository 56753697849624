.ln-c-alert {
    padding: 0.5rem;
    border-radius: 4px;
    position: relative;
    background-color: #4c4c4c;
    color: #fff;
    
    &.urgent-alert {
      margin-bottom: 30px;
  
      .ln-o-flag {
        margin-top:5px;
      }
    
      h5 {
        display: inline-block;
        margin-top:1px;
      }
    
      p {
        display: inline-block;
        margin-bottom: 10px;
      }
    
      svg {
        height: 25px;
        width: 25px;
        display: inline-block;
        margin-top: -14px;
        margin-left:50%;
      }
    }
    
    &.ln-c-alert--error {
      background-color: #d70000;
    }
  }