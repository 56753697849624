$ln-webpack: true;
$use-ln-fonts-small: true;

// luna
@import '@jsluna/foundation/scss/settings';
@import '@jsluna/foundation/scss/tools';

@import '@jsluna/primer/scss/generic';
@import '@jsluna/primer/scss/elements';
@import '@jsluna/fonts/scss/elements';
@import '@jsluna/typography/scss/elements';

@import '@jsluna/primer/scss/objects';
@import '@jsluna/grid/scss/objects';
@import '@jsluna/list/scss/objects';
@import '@jsluna/site-layout/scss/objects';

@import '@jsluna/autocomplete/scss/components';
@import '@jsluna/button/scss/components';
@import '@jsluna/card/scss/components';
@import '@jsluna/date-picker/scss/components';
@import '@jsluna/form/scss/components';
@import '@jsluna/header/scss/components';
@import '@jsluna/icons/scss/components';
@import '@jsluna/list/scss/components';
@import '@jsluna/link/scss/components';
@import '@jsluna/modal/scss/components';
@import '@jsluna/notification/scss/components';
@import '@jsluna/pagination/scss/components';
@import '@jsluna/progress/scss/components';
@import '@jsluna/table/scss/components';
@import '@jsluna/tabs/scss/components';
@import '@jsluna/toggle-button/scss/components';
@import '@jsluna/labs/src/alert/scss/main';

@import '@jsluna/utils/scss/utilities';

// shared
@import '@jsainsburyplc/activity-icons/scss/components';
@import '@jsainsburyplc/activity-survey/scss/main';
@import '@jsainsburyplc/retail-datetime-picker/scss/components';
@import '@jsainsburyplc/activity-markdown-reader/scss/components';
@import '@jsainsburyplc/activity-media-upload/scss/components';
@import '@jsainsburyplc/activity-media-display/scss/components';
@import '@jsainsburyplc/retail-unsupported-browser/scss/components';

// activity
@import 'activities';
@import 'viewActivity';
@import 'errorPage.scss';
@import 'header.scss';
@import 'print.scss';
@import 'calendar.scss';
@import 'completedSurvey.scss';
@import 'attributes.scss';
@import 'skeleton.scss';
@import 'dateTimePicker.scss';
@import 'audit.scss';
@import 'satisfaction.scss';
@import 'risk-compliance-report-card.scss';
@import 'banner.scss';
@import 'reporting.scss';
@import 'activity-card.scss';
@import 'activity-page.scss';
@import 'filter.scss';
@import 'history.scss';
@import 'messages.scss';
@import 'risk.scss';
@import 'tutorial.scss';
@import 'urgentBanner.scss';

a:not(.corona, .header__global-item, .ln-c-header__global-item,.ln-c-link),
.link:not(.corona) {
  text-decoration: none;

  svg {
    vertical-align: middle;

    &:hover {
      fill: $ln-color-orange;
    }
  }

  &:hover {
    svg {
      fill: $ln-color-orange;
    }
  }
}

.backLink {
  margin-bottom: 1em;
}

.store-search {
  padding-bottom: 1rem;

  + span {
    display: none;
  }
}

.u-pre-wrap {
  white-space: pre-wrap;
}

#storeList {
  margin-left: 1rem;
}

@media (min-width: 699px) {
  .ln-c-header {
    .ln-c-topbar__list {
      text-align: right;
    }
  }
}

.ln-o-container {
  max-width: none;
}
.ln-o-grid {
  font-size: inherit;
}

.u-margin-bottom-tiny {
  margin-bottom: 0.1rem;
}

.u-show-on-print {
  display: none;
}

.u-white-background {
  background-color: $ln-color-white;
}

.c-header-container {
  transition: height 0.6s ease;
  overflow-y: hidden;
}

.search-result-container {
  margin-top: 4vh;

  @include ln-respond-to('max-sm') {
    margin-top: 10vh;
  }
}

.category-nav {
  ul {
    margin-left: -1rem;
    margin-bottom: 0.5rem;
    li {
      padding: 1rem;
      font-size: 18px;
      list-style-type: none;
      display: inline-block;
      &.active {
        font-weight: bold;
        &:after {
          width: 100%;
        }
      }
      &:hover {
        cursor: pointer;
      }
      &:after {
        content: '';
        background: #f7903b;
        display: block;
        width: 0%;
        height: 2px;
        position: relative;
        top: 4px;
        transition: all 0.3s ease;
      }
    }
  }
}
@mixin avatarWithCount($size) {
  width: $size;
  height: $size;
  background-color: $ln-color-orange;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;

  .initials {
    position: relative;
    top: -($size * 0.25);
    font-size: $size * 0.3;
    line-height: $size * 0.5;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
  }

  .user-count {
    width: $size / 2;
    height: $size / 2;
    background-color: $ln-color-grey;
    text-align: center;
    border-radius: 50%;
    border: 2px solid $ln-color-white;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;

    position: relative;
    top: -($size * 0.15);
    left: $size * 0.6;
    font-size: $size * 0.3;
    line-height: $size * 0.425;
    text-transform: uppercase;
    color: $ln-color-white;
    font-weight: bold;
  }
}

@mixin avatar($size) {
  width: $size;
  height: $size;
  background-color: $ln-color-orange;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;

  .initials {
    position: relative;
    top: $size * 0.25;
    font-size: $size * 0.3;
    line-height: $size * 0.5;
    text-transform: uppercase;
    color: $ln-color-white;
    font-weight: bold;
  }
}

.avatar-circle {
  @include avatar(100px);
}
.avatar-circle-sm {
  @include avatar(50px);
}
.avatar-circle-lg {
  @include avatar(250px);
}

.avatar-with-count-circle {
  @include avatarWithCount(100px);
}
.avatar-with-count-circle-sm {
  @include avatarWithCount(50px);
}
.avatar-with-count-circle-lg {
  @include avatarWithCount(250px);
}

.modal-allow-overflow {
  .ln-c-modal__body {
    overflow: visible;
  }
}

@media (max-width: 719px) {
  .ln-c-header-body {
    margin: 0px;
  }
}

.header-mobile-item {
  @media (min-width: 720px) {
    display: none;
  }
}