.activity-page-padder {
  padding: 0;

  @include ln-respond-to("md") {
    padding: 0 $ln-space*4;
  }
}

.activity-page-attribute {
  align-items: center;
  background-color: $ln-color-grey-v-light;
  border: 1px solid $ln-color-grey-light;
  display: inline-flex;
  height: 35px;
  justify-content: center;
  margin-right: $ln-space;
  margin-top: $ln-space;
  padding: 0 $ln-space;
  vertical-align: middle;
}

.activity-page-event {
  align-items: center;
  background-color: $ln-color-grey-v-light;
  border: 1px solid $ln-color-grey-light;
  display: inline-flex;
  justify-content: center;
  min-width: $ln-space * 18;
  padding: $ln-space / 2 $ln-space;
}

.activity-page-event--blue {
  background-color: $ln-color-blue;
  border: 1px solid $ln-color-blue;
  color: $ln-color-white;
}

.activity-page-event--red {
  background-color: $ln-color-red;
  border: 1px solid $ln-color-red;
  color: $ln-color-white;
}

.activity-page-event--green {
  background-color: $ln-color-green;
  border: 1px solid $ln-color-green;
  color: $ln-color-white;
}

.activity-page-event--black {
  background-color: $ln-color-black;
  border: 1px solid $ln-color-black;
  color: $ln-color-white;
}

.activity-page-event--grey-light {
  background-color: $ln-color-grey-light;
  border: 1px solid $ln-color-grey-light;
  color: $ln-color-white;
}

.activity-page-event--grey-dark {
  background-color: $ln-color-grey-dark;
  border: 1px solid $ln-color-grey-dark;
  color: $ln-color-white;
}