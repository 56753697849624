.filter-panel {
  overflow-x: hidden;
  transition: 0.5s;
  width: 0;
  height: 0;
}

.filter-panel {
  &.is-open {
    width: 100%;
    height: auto;
    @include ln-respond-to('max-sm') {
      padding-top: 50px
    }
    border-right: 1px solid #d8d8d8;
  }
}

.filter-content {
  
  &.filter-top-margin {
    @include ln-respond-to("md") {
      margin-top: $ln-space * 2;
    }
  }

  @include ln-respond-to('max-sm') {
    padding-top: 56px
  }

  &.panel-is-open {
    display: none;

    @include ln-respond-to("md") {
      display: block;
    }
  }
}

.filter-pill {
  border-radius: 8px;
  display: inline-block;
  margin: $ln-space / 2  $ln-space $ln-space / 2 0;
  text-align: center;
}

.filter-pill--toggle {
  background-color: $ln-color-white;
  border: 1px solid $ln-color-grey;
  color: $ln-color-grey-dark;
  padding: 2px 6px;
}

.filter-pill--item {
  background-color: $ln-color-orange;
  border: none;
  color: $ln-color-white;
  padding: 3px 4px 3px 8px;
}