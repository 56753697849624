@media print {
    body {
        color: #4c4c4c;
        background-color: white !important;
        font-size: 1rem;
        line-height: 1.5;
        font-family: arial, sans-serif;
        font-weight: 400;
    }

    h1, h2 {
        font-family: MaryAnn, Trebuchet MS, sans-serif;
        font-size: 2em;
        font-weight: 700;
        margin: 0;
    }

    h1 {
        color: #f06c00;
    }

    .todo-item-box {
        border: none;
    }

    .ln-c-status-card {
        visibility: visible;
        border-left: 0px;
        padding: 0;
    }

    .ln-o-grid__item {
        display: block;
    }

    .ln-c-topbar,
    .ln-c-header-body,
    .ln-u-visually-hidden,
    .ln-c-header__toggle,
    .ln-c-header__toggle-overlay,
    .ln-c-navigation-wrapper__inner,
    #todo-item-right-arrow,
    #swipe-message {
        display: none;
    }
    .ln-o-container.ln-o-container--soft {
        padding-left: 0.5rem;
    }

    #today-items,
    #week-grouped-items,
    .ln-c-card--soft {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 0;
        padding-left: 0rem;
    }

    .u-show-on-print {
      display: inline-block !important;
    }

    .u-hide-on-print {
      display: none;
    }

    #section-heading {
        margin-bottom: 0.5rem;
        display: block;
    }

    #item-header {
        padding-top: 0.5rem;
        margin-bottom: 1rem;
    }

    .startedBadge {
        text-align: left !important;
        width: 3rem !important;
        color: #4c4c4c !important;
    }

    .listElementLabel {
        margin-top: 0 !important;
    }

    #todo-item-status-card {
        padding: 0 !important;
        margin-bottom: 0.5rem;
    }

    .ln-u-margin-bottom {
        margin-bottom: 0;
    }

    #activities-content-container {
        margin-top: 3rem;
    }

    #day-grouped-items-day-title,
    #item-header-container {
        font-weight: bold;
        padding-left: 0 !important;
    }

    #list-type-container {
        margin-bottom: 0.5rem !important;
    }

    .ln-c-status-card__content, #week-title {
        padding-left: 0 !important;
    }

    #notification-item {
      display: none;
    }
}
