.feedback-header {
    font-size: 30pt;
    margin-left: 10px;
    text-align: center;
}
.feedback-text {
    font-size: 16pt;
    text-align: center;
}

.tick-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    
    .green-circle {
        height: 100px;
        width: 100px;
        margin-left: 20px;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        border-radius: 50%;
        background: $ln-color-green;

        .circle-tick {
            color: $ln-color-white;
            margin-top:25px;
        }
    }
}