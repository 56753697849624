.activity-card {
  border: 1px solid $ln-color-grey-light;
  margin-bottom: -1px;

  @include ln-respond-to("md") {
    margin-bottom: $ln-space / 2;
  }
}

.activity-card--danger {
  border-left: 4px solid $ln-color-red;
}

.activity-card--job-families {
  color: $ln-color-grey-dark;
}

.activity-card--argos {
  color: #DB3E34;
}

.activity-card--customer-experience {
  color: #E6007E;
}

.activity-card--event {
  color: #1976D2;
}

.activity-card--food {
  color: #2E7D32;
}

.activity-card--food-services {
  color: #00438F;
}

.activity-card--gm-and-clothing {
  color: #774192;
}

.activity-card--groceries-online {
  color: #1976D2;
}

.activity-card--habitat {
  color: #231F20;
}

.activity-card--beauty {
  color: #DB3E34;
}

.activity-card--countdown-to-christmas {
  color: #DB3E34;
}

.activity-card--food-production {
  color: #7F0442;
}

.activity-card--line-managers {
  color: #7F0442;
}

.activity-restricted-icon {
  display: inline;
  svg {
    height: 2.0em;
    width: 1.5em;
  }
}

.activity-card--night-twilight-shift {
  color: #C6A600;
}

.activity-card--risk {
  color: #7F0442;
}

.activity-card--running-the-store {
  color: #E6007E;
}

.activity-card-badge {
  background-color: $ln-color-grey;
  border-radius: 50%;
  color: $ln-color-white;
  font-weight: bold;
  margin-right: $ln-space;
  padding: $ln-space / 2 $ln-space;
  text-align: center;
}

.activity-card-tag {
  background-color: $ln-color-grey-dark;
  border-radius: 5px;
  color: $ln-color-white;
  display: inline-block;
  margin: $ln-space $ln-space 0 0;
  padding: $ln-space / 2 $ln-space * 2;
  text-align: center;
  vertical-align: middle;
}

.activity-card-tag--success {
  background-color: $ln-color-green;
}

.activity-card-tag--danger {
  background-color: $ln-color-red;
}

.activity-wrap-description {
  word-wrap: break-word;
}