.corona {
  @include ln-respond-to('max-sm') {
    margin-top: 56px;
  }
  user-select: none;
  color: white;
  text-decoration: none;
  p {
    margin: 12px;
    font-size: 18px;
  }
  .main-banner {
    background: #d70000 !important;
    text-align: center !important;
  }
  .corona-emoji-container {
    height: 25px;
    width: 25px;
    background-color: $ln-color-grey-v-light;
    border-radius: 50%;
    margin: 12px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    .corona-message {
      background-color: rgba(0, 0, 0, 0.06);
      text-decoration: underline !important;
      text-decoration-thickness: 2px !important;
      box-shadow: 0 0 0 3px transparent !important;
    }
  }
}
