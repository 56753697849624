.u-tutorial-container {
  height:520px;
  width: 331px;
  .u-tutorial-image-container {
      height: 50%;
      background-color: $ln-color-grey-v-light;
      position: relative;
  
      .u-tutorial-image-container-image {
          position: absolute;
          max-height:200px;
          top: 50%;
          left: 53%;
          transform: translate(-50%, -50%);
      }
  }
  .u-tutorial-content-container {
      padding: 20px;
      height: 50%;

      .u-tutorial-content-container-buttons {
          position: absolute;
          left: 20%;
          width: 90%;
          margin-left: -50px;
          bottom: 20px;

          .u-tutorial-content-container-step-button {
              width:48%;
          }
      }
  }
  .right-button {
      float:right;
  }
}
.u-tutorial-button {
  width:105px;
}
.u-tutorial-mask > rect {
  fill:#4c4c4c;
}
#___reactour > div {
  opacity: 1 !important;
}
.reactour__helper {
  padding: 0 !important;
}