.activityView {
    word-wrap: break-word;

    .label {
        vertical-align: top;
        display: inline-block;
        width: 100px;
        font-family: MaryAnn,"Trebuchet MS",sans-serif;
        font-weight: 900;
        margin-right: 1rem;
    }
    .value {
        word-break: keep-all;
        word-wrap: normal;
        display: inline-block;
        text-align: left;
    }
    .row{
        margin-bottom: 1rem;        
        clear: both;
    }

    &.overdue {
        .dueValue {
            color: #C92319;
            font-weight: bold;
        }
    }
    
    @media (max-width: 700px) {
        .value {
            width: 70%;
        }
    }

    @media (max-width: 460px) {
        .ln-c-button--filled :not(.next) {
            width:100%
        }
        .ln-c-button--filled.ln-c-button--dark {
            width:100%
        }
        .label {
            word-wrap: normal;
            word-break: keep-all;
        }
        .value {
            width: 50%;
        }
    }    
}

.view-activity-container{
    margin-top:4rem;
    @include ln-respond-to('sm') {
      margin-top: 0.5rem !important;
    }
}