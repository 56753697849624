.history-item-completed-tag{
    margin-bottom: 0px;
    background-color: green;
    max-width: fit-content;
    color: white;
    padding: 0px 5px 0px 5px;
}

.history-item-missed-tag{
    margin-bottom: 0px;
    background-color: #bd2020;
    max-width: fit-content;
    color: white;
    padding: 0px 5px 0px 5px;
}
