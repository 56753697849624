@keyframes pulse {
    0% {
        background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
        background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
        background-color: rgba(165, 165, 165, 0.1);
    }
}

@mixin nth-children($points...) {
    @each $point in $points {
        &:nth-child(#{$point}) {
            @content;
        }
    }
}

.text-input__loading {
    &--line {
        height: 10px;
        margin: 10px;
        &.activity-title {
            height:20px
        }
        &.activity-assign-control {
            height:40px;
        }
        &.label-no-margin {
            margin: 0px;
        }
        animation: pulse 1s infinite ease-in-out;
    }
    &.attribute-item {
        .name-container {
            div {
                @include nth-children(1) {
                    width: 50px;
                }
            }
        }
        .activity-count-container {
            div {
                @include nth-children(1) {
                    width: 75px;
                }
            }
        }
    }

    &.category-item {
        div {
            @include nth-children(1) {
                width: 125px;
            }
        }
    }

    div {
        &.activity-label-info {
            width: 300px;
        }
        &.activity-desc {
            width:100% !important;
        }
        &.activity-media {
            margin:10px 10px 0px 0px;
            height:150px;
            width:150px !important;
        }
        &.inline-block {
            display: inline-block;
        }
        @include nth-children(3) {
            width: 200px
        }
    }
}