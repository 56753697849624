.audit {
  display: block;
  margin-right: 1rem;
  padding-left: 1;

  &.subtitle {
    font-family: MaryAnn, 'Trebuchet MS', sans-serif;
  }
  &.strong {
    font-weight: 900;
  }
}

.superseded {
  color: grey;
}

.strike:before {
  content: ' ';
  position: absolute;
  top: 53%;
  left: 0;
  border-bottom: 1px solid grey;
  @media (max-width: 460px) {
    width: 100%;
  }
  @media (min-width: 461px) {
    width: 40%;
  }
}

.strike {
  position: relative;
  text-decoration-color: grey;
}

.modal-button-group {
  @media (max-width: 768px) {
    padding-top: 1rem;
    min-width: 100%;
  }
}

.skeleton-audit {
  &.container {
    margin-left: 20px;
    width: 75%;
  }

  &.title {
    margin-bottom: 15px;
    margin-left: 0px;
  }

  &.text {
    margin-left: 0;
  }
}

.audit-button-group {
  @media (max-width: 650px) {
    margin-bottom: 1rem;
    display: grid;
    align-content: center;
  }
}

.checklist-header-disabled {    
  color: $ln-color-grey-light; 
}
