.risk-compliance-report-card {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid $ln-color-grey-light;
    padding: 20px;
}

.risk-compliance-report-card-value {
    font-size: 200%;
    font-weight: bold;
}

.risk-compliance-report-card--green {
    color: $ln-color-green;
}

.risk-compliance-report-card--red {
    color: $ln-color-red;  
}

.risk-compliance-report-card--blue {
    color: $ln-color-blue;  
}

.risk-compliance-report-card--plum {
    color: $ln-color-plum;  
}