.nts-message-icon {
	border-radius: 100%;
	background-color: #2e7d32;
	color: #fff;
	padding: $ln-space / 2 $ln-space;

	@include ln-respond-to("md") {
		padding: $ln-space / 1.5 $ln-space * 1.5;
	}
}

.nts-message-body {
	padding-left: 0%;
	padding-right: 0%;

	@include ln-respond-to("md") {
		padding-left: 25%;
		padding-right: 25%;
	}
}